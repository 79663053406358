<template>
    <div class="container mt-5 mb-6">
        <div class="row">
            <div class="col-12 col-md-12">
                <b-card
                    border-variant="primary"
                    class="mb-5 shadow-sm ">
                    <div class="container">
                        <div class="row">
                            <p class="lead text-black-50 m-0" v-text="'Product Information'" />
                        </div>
                        <hr class="hr-gradient mt-3 mb-3" />
                        <div class="row">
                            <div class="col-4 col-md-4">
                                <div class="row">Type: {{ configuration.PlanListing.type }}</div>
                                <div class="row fs-sm">Run Time: {{ configuration.PlanListing.runTime }}</div>
                                <div class="row">Minimum Score: {{ configuration.PlanListing.minScore }} | Maximum Score: {{ configuration.PlanListing.maxScore }}</div>
                            </div>
                            <span class="divider-vertical divider-primary"></span>
                            <div class="col-4 col-md-4">
                                <ul class="m-0" v-for="data in configuration.PlanListing.steps" :key="data._id">
                                    <li style="list-style: none;" class="m-0">{{ data.widget }} <i class="fa fa-info-circle" v-b-popover.hover.top="popStep(data)" :title="data.widget + ' Widget'" /></li>
                                </ul>
                            </div>
                            <span class="divider-vertical divider-dark"></span>
                            <div class="col-4 col-md-4">
                                <ul class="m-0" v-for="data in configuration.PlanListing.actions" :key="data._id">
                                    <li style="list-style: none;">{{ data.action }} <i class="fa fa-info-circle"  v-b-popover.hover.top.html="popAction(data)" :title="data.action + ' Action'" /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </b-card>
                <div class="card text-center mb-5 animate__animated animate__fadeIn" >
                    <div class="card-header d-flex justify-content-start h5 text-white" style="background-color: #444444;">Workflow Configuration</div>
                    <div class="card-body">
                        <div class="card shadow-sm">
                            <div class="card-header d-flex justify-content-start h6">Type: {{ configuration.PlanListing.name }}</div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-6 col-md-6">
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">Name:</label>
                                            <div class="col-sm-9 m-0">
                                                <input type="text" v-model="workflow_config.config_name" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">Keywords:</label>
                                            <div class="col-sm-9 m-0">
                                                <b-form-tags v-model="workflow_config.keywords" tag-variant="primary" placeholder="Add Keywords" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">Exclusions:</label>
                                            <div class="col-sm-9 m-0">
                                                <b-form-tags tag-variant="primary" placeholder="Add Exclusion" v-model="workflow_config.exclusions" class="form-control" />
                                            </div>
                                        </div>
<!--                                        <div class="form-group row">-->
<!--                                            <label class="col-sm-3 col-form-label">Price Range:</label>-->
<!--                                            <div class="col-sm-4 m-0">-->
<!--                                                <b-input-group prepend="$">-->
<!--                                                    <b-form-input type="number" v-model="workflow_config.price_range.price_range_start" class="form-control"></b-form-input>-->
<!--                                                </b-input-group>-->
<!--                                                <small class="text-muted d-flex justify-content-start">Start Price</small>-->
<!--                                            </div>-->
<!--                                            <div class="">-->
<!--                                                <small class="text-muted">TO</small>-->
<!--                                            </div>-->
<!--                                            <div class="col-sm-4 m-0">-->
<!--                                                <b-input-group prepend="$">-->
<!--                                                    <b-form-input type="number" v-model="workflow_config.price_range.price_range_end" class="form-control"></b-form-input>-->
<!--                                                </b-input-group>-->
<!--                                                <small class="text-muted d-flex justify-content-start">End Price</small>-->
<!--                                            </div>-->
<!--                                        </div>-->
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">Description:</label>
                                            <div class="col-sm-9 m-0">
                                                <textarea type="text" v-model="workflow_config.description" class="form-control" rows="3" style="resize: none" />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">Notes:</label>
                                            <div class="col-sm-9 m-0">
                                                <textarea type="text" v-model="workflow_config.notes" class="form-control" rows="2" style="resize: none" />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">Time of Search:</label>
                                            <div class="col-4 pr-0 text-left">
                                                <b-input-group class="" size="sm">
                                                    <b-form-input id="StartDate" v-model="workflow_config.start_date" type="text" placeholder="YYYY-MM-DD" autocomplete="off"></b-form-input>
                                                    <b-input-group-append >
                                                        <b-form-datepicker button-only right locale="en-US" v-model="workflow_config.start_date" aria-controls="StartDate" size="sm"></b-form-datepicker>
                                                    </b-input-group-append>
                                                </b-input-group>
                                                <small class="text-muted">Start Date</small>
                                            </div>
                                            <div class="col-4 ml-4 pr-0 text-left">
                                                <b-input-group class="" size="sm">
                                                    <b-form-input id="StopDate" type="text" v-model="workflow_config.stop_date" placeholder="YYYY-MM-DD" autocomplete="off"></b-form-input>
                                                    <b-input-group-append >
                                                        <b-form-datepicker button-only right v-model="workflow_config.stop_date" locale="en-US" aria-controls="StopDate" size="sm"></b-form-datepicker>
                                                    </b-input-group-append>
                                                </b-input-group>
                                                <small class="text-muted">End Date</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-2 mb-3 d-flex justify-content-center">
                                    <h6>Automated Actions <hr /></h6>

                                </div>
                                <div class="row">
                                    <div class="col-4 col-md-4" v-for="action in workflow_config.actions" :key="action._id">
                                        <b-form-group v-if="action.action == 'Possible Email Match'" :label="action.action" class="col-9 col-sm-9 m-0">
                                            <b-form-input type="email" v-model="action.action_info.email" class="form-control" />
                                        </b-form-group>

                                        <b-form-group v-if="action.action == 'SMS Notification'" :label="action.action" class="col-9 col-sm-9 m-0">
                                            <b-form-input type="tel" v-model="action.action_info.telephone" class="form-control" />
                                        </b-form-group>

                                        <b-form-group v-if="action.action == 'Notify Account Manager'" :label="action.action" class="col-9 col-sm-9 m-0">
                                            <b-form-input type="email" v-model="action.action_info.email" class="form-control" />
                                        </b-form-group>

                                        <b-form-group v-if="action.action == 'High Accuracy Email'" :label="action.action" class="col-9 col-sm-9 m-0">
                                            <b-form-input type="email" v-model="action.action_info.email" class="form-control" />
                                        </b-form-group>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer border-top-0 d-flex justify-content-end">
                                <p class="m-0 text-muted cancel" @click="cancel">CANCEL</p>
                            </div>
                        </div>

                        <hr class="mt-5 mb-5" />

                        <div class="widgets" v-for="( widget, p_index ) in workflow_config.steps" :key="p_index">
                            <div v-if="widget.key === 'img-widget'">
                                <div class="card card-hover mt-3">
                                    <div class="card-body">
                                        <div class="container">
                                            <div class="row">
                                                <h6>Image 00{{p_index}}</h6>
                                            </div>
                                            <div class="row">
                                                <div class="col-5 col-md-5">
                                                    <div class="cs-file-drop-area">
                                                        <div class="cs-file-drop-icon cs-upload"></div>
                                                        <span class="cs-file-drop-message">Drag and drop here to upload</span>
                                                        <form enctype="multipart/form-data" novalidate>
                                                            <input type="file" :ref="widget.step_id" id="file" name="filename" multiple class="cs-file-drop-input" @change="filesChange($event.target.name, $event.target.files, p_index);" :accept="widget.widget_options.img_file_types">
                                                        </form>
                                                        <button type="button" @click="up(widget.step_id)" class="file-drop-btn btn btn-outline-primary btn-sm">Or select file</button>
                                                    </div>
                                                </div>

                                                <div class="col-7 col-md-7">
                                                    <p class="lead" v-if="widget && widget.files.length === 0">No Images have been saved.</p>
                                                    <div class="card-carousel" v-if="widget && widget.files.length > 0">
                                                        <div class="card-img" @mouseover="show = true" @mouseleave="show = false">
                                                            <transition enter-active-class="animate__animated animate__fadeInUp" leave-active-class="animate__animated animate__fadeOut">
                                                                <i class="fa fa-times-circle closeIcon" @click="remove( workflow_config._id, widget.step_id, currentImageId(p_index) )" v-if="show" />
                                                            </transition>
                                                            <img width="350" :src="`${$root.serverUrl}/admin/download?url=${encodeURI(currentImage(p_index))}`">
                                                            <div class="actions">
                                                                <transition enter-active-class="animate__animated animate__animate__fadeInLeft" leave-active-class="animate__animated animate__fadeOutRight">
                                                                    <span @click="prevImage(p_index)" class="prev" v-if="show"><i class="fa fa-angle-left text-white shadow-sm"></i></span>
                                                                </transition>
                                                                <transition enter-active-class="animate__animated animate__animate__fadeInRight" leave-active-class="animate__animated animate__fadeOutLeft">
                                                                    <span @click="nextImage(p_index)" class="next" v-if="show"><i class="fa fa-angle-right text-white"></i></span>
                                                                </transition>
                                                            </div>
                                                        </div>
                                                        <div class="thumbnails" v-if="widget.files.length > 1">
                                                            <div
                                                                v-for="(image, index) in widget.files"
                                                                :key="image.id"
                                                                :class="['thumbnail-image', ( activeImage === index ) ? 'active' : '']"
                                                                @click="activateImage(index)"
                                                            >
                                                                <img :src="`${$root.serverUrl}/admin/download?url=${encodeURI(image.url)}`">
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="widget.key === 'txt-widget'">
                                <div class="card card-hover mt-3">
                                    <div class="card-body">
                                        <div class="container">
                                            <div class="row">
                                                <h6>Name  - Text 001</h6>
                                            </div>
                                            <div class="row">
                                                <div class="col-5 col-md-5 text-left">
                                                    <b-form-group
                                                        label="Targeted Elements"
                                                        v-slot="{ ariaDescribedby }"
                                                    >
                                                        <b-form-checkbox
                                                            v-for="option in tar_options"
                                                            v-model="widget.widget_options.elements"
                                                            :key="option.value"
                                                            :value="option.value"
                                                            :aria-describedby="ariaDescribedby"
                                                        >
                                                            {{ option.text }}
                                                        </b-form-checkbox>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-7 col-md-7">
                                                    <b-form-textarea v-model="widget.text" class="form-control" rows="10" > </b-form-textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="widget.key === 'vid-widget'">
                                <div class="card card-hover mt-3">
                                    <div class="card-body">
                                        <div class="container">
                                            <div class="row">
                                                <h6>Name  - Video 00{{p_index}}</h6>
                                            </div>
                                            <div class="row">
                                                <div class="col-5 col-md-5">
                                                    <div class="cs-file-drop-area">
                                                        <div class="cs-file-drop-icon cs-upload"></div>
                                                        <span class="cs-file-drop-message">Drag and drop here to upload</span>
                                                        <form enctype="multipart/form-data" novalidate>
                                                            <input type="file" :ref="widget.step_id" name="filename" multiple class="cs-file-drop-input" @change="filesChange($event.target.name, $event.target.files, p_index);" :accept="widget.widget_options.video_file_types">
                                                        </form>
                                                        <button type="button" @click="up(widget.step_id)" class="file-drop-btn btn btn-outline-primary btn-sm">Or select file</button>
                                                    </div>
                                                </div>

                                                <div class="col-7 col-md-7">
                                                    <p class="lead" v-if="widget.files.length === 0">No Videos have been saved.</p>
                                                    <div class="card-carousel" v-if="widget.files.length > 0">
                                                        <div class="card-img" @mouseover="show = true" @mouseleave="show = false">
                                                            <transition enter-active-class="animate__animated animate__fadeInUp" leave-active-class="animate__animated animate__fadeOut">
                                                                <i class="fa fa-close closeIcon" @click="remove( workflow_config._id, widget.step_id, currentImageId(p_index) )" v-if="show" />
                                                            </transition>
                                                            <b-embed type="video" controls :src="`${$root.serverUrl}/admin/download?url=${encodeURI(currentImage(p_index))}`"></b-embed>
                                                            <div class="actions">
                                                                <transition enter-active-class="animate__animated animate__animate__fadeInLeft" leave-active-class="animate__animated animate__fadeOutRight">
                                                                    <span @click="prevImage(p_index)" class="prev" v-if="show"><i class="fa fa-angle-left" ></i></span>
                                                                </transition>
                                                                <transition enter-active-class="animate__animated animate__animate__fadeInRight" leave-active-class="animate__animated animate__fadeOutLeft">
                                                                    <span @click="nextImage(p_index)" class="next" v-if="show"><i class="fa fa-angle-right"></i></span>
                                                                </transition>
                                                            </div>
                                                        </div>
                                                        <div class="thumbnails" v-if="widget.files.length > 1">
                                                            <div
                                                                v-for="(image, index) in widget.files"
                                                                :key="image.id"
                                                                :class="['thumbnail-image', ( activeImage === index ) ? 'active' : '']"
                                                                @click="activateImage(index)"
                                                            >
                                                                <img :src="`${$root.serverUrl}/admin/download?url=${encodeURI(image.url)}`">
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-5 mb-3 d-flex justify-content-center">
                            <div class="col-3 col-md-3">
                                <button type="button" class="btn btn-outline-primary" @click="save" v-text="'SAVE CONFIGURATION'" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
// import configuration_selection from '@/components/workflow/configuration_selection'
import { v4 as uuidv4 } from 'uuid';
import Vue from "vue"
import swal from 'sweetalert'
import Swal from "sweetalert";
import axios from "axios"
import fontawesome from "@fortawesome/vue-fontawesome"
Vue.component('fontawesome', fontawesome)

const STATUS_INITIAL = 'init', STATUS_SAVING = 'saving';

export default {
    name: "DomainManagement",
    props: ['user'],
    data() {
        return {
            list: true,
            currentPage: 1,
            perPage: 5,
            items: [],
            time: 0,
            show: false,
            configuration: { PlanListing: {} },
            currentStatus: '0_init',
            workflow_config: { price_range: {} },
            active: 1,
            activeImage: 0,
            tar_options: [
                { text: 'Labels', value: 'labels' },
                { text: 'Spans', value: 'spans' },
                { text: 'Paragraphs', value: 'paragraphs' },
                { text: 'Headers', value: 'headers' },
                { text: 'Divs', value: 'divs' },
                { text: 'Images OCR', value: 'i_ocr' },
            ]
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'config_name',
                    label: 'Name',
                    sortable: false
                },
                {
                    key: 'type',
                    label: 'Type',
                    sortable: false
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: false
                },
                {
                    key: 'actions',
                    label: 'Actions',
                    sortable: false
                }
            ]
        },
    },
    watch: { },
    created() { this.getConfigs() },
    methods: {
        up(refName) {
            this.$refs[refName][0].click()
        },
        popStep(data) {
            return 'Value: ' + data.value
        },
        popAction(data) {
            return 'Value: ' + data.value + `<br />` + 'Result: ' + data.result
        },
        currentImage(index) {
            return this.workflow_config.steps[index].files[this.activeImage].url;
        },
        currentImageId(index) {
            return this.workflow_config.steps[index].files[this.activeImage].id;
        },
        validate() {
            if ( !this.workflow_config.config_name || this.workflow_config.config_name === '' ) {
                this.$bvToast.toast('No Configuration name not set.', {
                    title: `Warning: Configuration Name`,
                    variant: `warning`,
                    solid: true
                })
                return false
            } else if ( !this.workflow_config.keywords || this.workflow_config.keywords.length === 0 ) {
                this.$bvToast.toast('No Keywords not selected.', {
                    title: `Warning: Keywords`,
                    variant: `warning`,
                    solid: true
                })
                return false
            } else if ( !this.workflow_config.exclusions || this.workflow_config.exclusions.length === 0 ) {
                this.$bvToast.toast('Exclusions not set.', {
                    title: `Warning: Exclusions`,
                    variant: `warning`,
                    solid: true
                })
                return false
            }
            // else if ( !this.workflow_config.price_range.price_range_start || this.workflow_config.price_range.price_range_start === '' ) {
            //     this.$bvToast.toast('Start Price Range not set.', {
            //         title: `Warning: Start Price`,
            //         variant: `warning`,
            //         solid: true
            //     })
            //     return false
            // } else if ( !this.workflow_config.price_range.price_range_end || this.workflow_config.price_range.price_range_end === '' ) {
            //     this.$bvToast.toast('End Price Range not set.', {
            //         title: `Warning: End Price`,
            //         variant: `warning`,
            //         solid: true
            //     })
            //     return false
            // } else if ( this.workflow_config.price_range.price_range_start && this.workflow_config.price_range.price_range_end ) {
            //     let start = parseInt(this.workflow_config.price_range.price_range_start)
            //     let stop = parseInt(this.workflow_config.price_range.price_range_end)
            //     if ( start > stop ) {
            //         this.$bvToast.toast('Start price cannot be less that end price.', {
            //             title: `Warning: Price Range`,
            //             variant: `warning`,
            //             solid: true
            //         })
            //         return false
            //     } else { return true }
            // }
            else if ( !this.workflow_config.description || this.workflow_config.description === '' ) {
                this.$bvToast.toast('Description not set.', {
                    title: `Warning: Description`,
                    variant: `warning`,
                    solid: true
                })
                return false
            } else if ( !this.workflow_config.notes || this.workflow_config.notes === '' ) {
                this.$bvToast.toast('No notes entered.', {
                    title: `Warning: Notes`,
                    variant: `warning`,
                    solid: true
                })
                return false
            } else if ( !this.workflow_config.start_date || this.workflow_config.start_date === '' ) {
                this.$bvToast.toast('Start Date not set.', {
                    title: `Warning: Start Date`,
                    variant: `warning`,
                    solid: true
                })
                return false
            } else if ( !this.workflow_config.stop_date || this.workflow_config.stop_date === '') {
                this.$bvToast.toast('Stop Date not set.', {
                    title: `Warning: Stop Date`,
                    variant: `warning`,
                    solid: true
                })
                return false
            } else if ( this.workflow_config.start_date && this.workflow_config.stop_date ) {
                if ( new Date(this.workflow_config.start_date) > new Date(this.workflow_config.stop_date) ) {
                    this.$bvToast.toast('Stop date is behind Start Date.', {
                        title: `Warning: Start/Stop Date`,
                        variant: `warning`,
                        solid: true
                    })
                    return false
                } else { return true }
            } else {
                return true
            }
        },
        save() {
            if ( this.validate() ) {
                if ( this.workflow_config._id  ) {
                    axios.put(`${this.$root.serverUrl}/admin/workflowConfig`, this.workflow_config ).then(( resp ) => {
                        if (resp && resp.data.updated) {
                            swal({ title: 'Configuration Updated', text: 'Workflow updated successfully', icon: 'success' })
                        }
                    })
                } else {
                    this.workflow_config.ProductId = this.configuration.ProductId
                    this.workflow_config.ClientId = this.configuration.ClientId
                    this.workflow_config.PlanId = this.configuration.PlanId
                    this.workflow_config.ClientProductId = this.$route.query.client_product_id
                    this.workflow_config.status = 'active'

                    axios.post(`${this.$root.serverUrl}/admin/workflowConfig`, this.workflow_config ).then((resp) => {
                        if (resp) {
                            swal({ title: 'Configuration Created', text: 'Workflow created successfully', icon: 'success' })
                        }
                    })
                }
            } else {
                this.$bvToast.toast('The form has Errors', {
                    title: `Warning: Form Errors`,
                    variant: `danger`,
                    solid: true
                })
            }
        },
        cancel() {
            this.$router.go(-1)
        },
        remove( conf, step, id ) {
            if ( !conf || conf === undefined) {
                for ( let data of this.workflow_config.steps ) {
                    if ( data.step_id === step ) {
                        for ( let i = 0; i < data.files.length; i++ ) {
                            if ( data.files[i].id === id ) {
                                data.files.splice(i, 1)
                                this.$bvToast.toast('File Deleted successfully.',{
                                    title: 'Content Update',
                                    variant: 'success',
                                    solid: true
                                })
                            }
                        }
                    }
                }
            } else {
                axios.delete(`${this.$root.serverUrl}/admin/workflowConfig/image/${conf}/${step}/${id}` ).then((resp) => {
                    if (resp && resp.data.deleted) {
                        this.getConfigs()
                        this.$bvToast.toast('File Deleted successfully.',{
                            title: 'Content Update',
                            variant: 'success',
                            solid: true
                        })
                    }
                    if (resp && resp.data.message) {
                        swal({title: 'Error', text: resp.data.message, icon: 'error'})
                    }
                })
            }
        },
        removeWFConfig(id) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.delete(`/configure/${id}`).then(( resp ) => {
                        if (resp && resp.data._id) {
                            Swal.fire(
                                'Deleted!',
                                `Your Workflow Configuration, ${resp.data.config_name} has been deleted.`,
                                'success'
                            ).then(() => this.getConfigs() )
                        }
                    })
                }
            })
        },
        async getConfigs() {
            axios.get(`${this.$root.serverUrl}/admin/clients/product/${this.$route.query.plan_id}`).then(async (resp) => {
                this.configuration = (resp && resp.data) ? Object.assign(resp.data.data) : {}
                await axios.get(`${this.$root.serverUrl}/admin/workflowConfig/${this.$route.query.config_id}`).then((resp) => {
                    let data = (resp && resp.data) ? Object.assign({}, resp.data) : {}
                    this.loadConfiguration(data);
                })
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                this.$root.preloader = false;
            })
        },
        loadConfiguration(obj) {
            if ( !obj._id ) {
                this.workflow_config = {}
                this.workflow_config.price_range = {}
                this.workflow_config.steps = []
                this.workflow_config.actions = []

                for ( let i = 0; i < this.configuration.PlanListing.steps.length; i++ ) {
                    this.workflow_config.steps.push(this.configuration.PlanListing.steps[i])
                    this.workflow_config.steps[i].files = []
                }

                for ( let i = 0; i < this.configuration.PlanListing.actions.length; i++ ) {
                    this.workflow_config.actions.push(this.configuration.PlanListing.actions[i])
                    this.workflow_config.actions[i].action_info = {}
                }
            } else {
                this.workflow_config = Object.assign({}, obj )
            }
        },
        nextImage(index) {
            let active = this.activeImage + 1;
            if(active >= this.workflow_config.steps[index].files.length) {
                active = 0;
            }
            this.activateImage(active);
        },
        prevImage(index) {
            let active = this.activeImage - 1;
            if(active < 0) {
                active = this.workflow_config.steps[index].files.length - 1;
            }
            this.activateImage(active);
        },
        activateImage(imageIndex) {
            this.activeImage = imageIndex;
        },
        getIndex(str) {
            return str.split('_')[0]
        },
        getStatus(str) {
            return str.split('_')[1]
        },
        progCount(index) {
            console.log(index)
            this.currentStatus = index +'_'+ STATUS_SAVING;

            let terval = setInterval(() => {
                this.time++
                if ( this.time === 10 ) {
                    this.currentStatus = index +'_'+ STATUS_INITIAL;
                    clearInterval(terval);
                    this.time = 0
                }
            }, 400 )
        },
        saveFile(formData, index) {
            // upload data to the server
            return axios.post(`${this.$root.serverUrl}/admin/workflowConfig/upload`, formData)
                .then(x=>x.data)
                .then(x => x.map(img => Object.assign({}, img)))
                .then(x => {
                    this.uploadedFiles = [].concat(x);
                    this.currentStatus = STATUS_INITIAL;
                    for( let i = 0; i < this.uploadedFiles.length; i++ )
                        this.workflow_config.steps[index].files.push({ filename: this.uploadedFiles[i].originalname, id: uuidv4(), url: this.uploadedFiles[i].path, mimetype: this.uploadedFiles[i].mimetype, mat: null });
                    this.$forceUpdate()
                }).catch(err => {
                    this.uploadError = err.response;
                    // this.currentStatus = STATUS_FAILED;
                });

        },
        filesChange(fieldName, fileList, index) {
            // handle file changes
            const formData = new FormData();

            if (!fileList.length) return;

            // append the files to FormData
            Array
                .from(Array(fileList.length).keys())
                .map(x => {
                    formData.append(fieldName, fileList[x], fileList[x].name);
                });

            // save it
            this.saveFile(formData, index);
        },
    }
}
</script>

<style scoped>
.cs-file-drop-area {
    position: relative;
    padding: 1.5rem 1rem;
    transition: border-color .2s ease-in-out,background-color .2s ease-in-out,box-shadow .2s ease-in-out;
    border: 2px dashed #dadbdd;
    border-radius: .25rem;
    text-align: center;
    cursor: pointer;
}
.cs-file-drop-area .cs-file-drop-input {
    opacity: 0;
}
input[type=file]::-webkit-file-upload-button {
    visibility: hidden;
}

.card-carousel {
    user-select: none;
    position: relative;
}

.progressbar {
    display: block;
    width: 100%;
    height: 5px;
    position: absolute;
    background-color: rgba(221, 221, 221, 0.25);
    z-index: 1;
}

.progressbar > div {
    background-color: rgba(255, 255, 255, 0.52);
    height: 100%;
}

.thumbnails {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
}

.thumbnail-image {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 2px;
}

.thumbnail-image > img {
    width: 100%;
    height: auto;
    transition: all 250ms;
}

.thumbnail-image:hover > img,
.thumbnail-image.active > img {
    opacity: 0.6;
    box-shadow: 2px 2px 6px 1px rgba(0,0,0, 0.5);
}

.card-img {
    position: relative;
    margin-bottom: 15px;
}

.card-img > img {
    display: block;
    margin: 0 auto;
}

.actions {
    font-size: 1.5em;
    height: 40px;
    position: absolute;
    top: 50%;
    margin-top: -20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #585858;
}

.actions > span {
    cursor: pointer;
    transition: all 250ms;
}

.actions > span.prev {
    margin-left: 5px;
}

.actions > span.next {
    margin-right: 5px;
}

.actions > span:hover {
    color: #388bce;
}

.closeIcon {
    font-size: 25px;
    color: #ff0f0f;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 10px;
    cursor: pointer;
}

.closeIcon:hover {

}

.cancel {
    cursor: pointer;
}

.cancel:hover {
    color: #388bce !important;
    font-weight: bold;
}

</style>
